import axios, { Method, AxiosRequestConfig } from 'axios';

interface IConfig {
  headers?: AxiosRequestConfig['headers'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
}

const httpClient = axios.create({
  // baseURL: 'https://tikbuy.com/api/',
  baseURL: '/api/',
  timeout: 10000,
  headers: { 'X-client': 'parse-client, 0.1' },
});

export function request(method: Method, url: string, config?: IConfig) {
  return httpClient({
    method,
    url,
    ...config,
  });
}

export const get = (url: string, config?: IConfig) => request('GET', url, config);

export const post = (
  url: string,
  data: any,
  config?: IConfig,
) => httpClient.post(url, data, config);

export const head = (url: string, config?: IConfig) => request('HEAD', url, config);

export const del = (url: string, config?: IConfig) => request('DELETE', url, config);
