import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  IconButton,
  useTheme,
} from '@material-ui/core';
import {
  List as ListIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Home as HomeIcon,
  TransformTwoTone,
} from '@material-ui/icons';
import { ProviderAvatar } from '../provider';
import Styles from './side-menu.styles';
import { selectUser } from '../../slices/user.slice';
import { getUserProviders } from './side-menu.helpers';

interface IProps {
  open: boolean;
  handleDrawerClose: () => void;
}
const MenuDrawer: React.FC<IProps> = ({ open, handleDrawerClose }) => {
  const classes = Styles();
  const theme = useTheme();
  const user = useSelector(selectUser);

  const providers = useMemo(() => getUserProviders(user), [user]);

  return (
    <Drawer
      anchor={'left'}
      open={open}
      ModalProps={{ onBackdropClick: handleDrawerClose }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      {user.loggedin
        && <List>
          <ListItem component={Link} to="/" button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Playlists:" />
          </ListItem>
          {providers.map((provider) => (
            <ListItem
              key={provider.id}
              component={Link}
              // FIXME:: to make sure we are using proper type
              to={provider.link || ''}
              button
            >
              <ListItemIcon>
                <ProviderAvatar provider={provider} />
              </ListItemIcon>
              <ListItemText primary={provider.title} />
            </ListItem>
          ))}
        <Divider />
        { user.transfers
          && <ListItem component={Link} to="/transfer/report" button>
              <ListItemIcon>
                <TransformTwoTone />
              </ListItemIcon>
              <ListItemText primary="Transfers" />
            </ListItem>
        }
        </List>
      }{!user.loggedin
        && <List>
          <ListItem component={Link} to="/" button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </List>
      }
    </Drawer>
  );
};

export default MenuDrawer;
