import React, { Fragment, useEffect, useState } from 'react';
import { Avatar } from '@material-ui/core';
import { providerInterface } from '../index';
import { icons } from './provider-logo.constant';

const ProviderAvatar: React.FC<{
  className?: string;
  provider: providerInterface;
}> = ({ className, provider }) => {
  const [imageURL, setImageURL] = useState('');
  const [letter, setLetter] = useState('');
  useEffect(() => {
    const theId = provider.id as keyof typeof icons;
    if (icons[theId]) {
      setImageURL(icons[theId]);
    } else {
      setLetter(provider.title[0]);
    }
  }, [provider]);
  return (
    <Fragment>
      { imageURL
        && <Avatar alt="Remy Sharp" src={imageURL} className={className} />
      }
      { !imageURL && letter
        && <Avatar className={className}>{letter}</Avatar>
      }
      { !imageURL && !letter
        && <Avatar className={className}>12</Avatar>
      }
    </Fragment>
  );
};

export default ProviderAvatar;
