import React from 'react';

import Root from './app/routes';
import { useAuth } from './hooks/hooks';

import './App.css';
import { useTransfer } from './hooks';

const App: React.FC = () => {
  useAuth();
  useTransfer();
  return <Root />;
};

export default App;
