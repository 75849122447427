import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SideMenu from '../side-menu';
import { selectUser } from '../../slices/user.slice';
import { LoginPanel } from '../main';
import {
  selectOpenLoginPupop, setOpenLoginPupop,
} from '../../slices/app.slice';
import Copyright from '../copyright';
import Styles from './dashboard.styles';

const Dashboard: React.FC = ({ children }) => {
  const classes = Styles();
  const isOpenloginPupop = useSelector(selectOpenLoginPupop);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const Title = 'Transfer playlist';
  useEffect(() => {
    if (user.loggedin) {
      dispatch(setOpenLoginPupop(false));
    }
  }, [dispatch, user.loggedin, user.providers]);

  return (
    <Container className={classes.container100}>

      <SideMenu />

      <div className={classes.content}>
        {children}
      </div>

      <div className={classes.fixedFooter}>
        <Copyright title={Title} />
      </div>

      <LoginPanel
        open={isOpenloginPupop}
        setOpen={(isOpen) => { dispatch(setOpenLoginPupop(isOpen)); }} />

    </Container>
  );
};

export default Dashboard;
