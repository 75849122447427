import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_APP = {
  isInitialLoad: true,
  isOpenLoginPupop: false,
  isRedirectAfterLogin: false,
  isStartTransferAfterGo: false,
  sourceProvider: {},
  destinationProvider: {},
};

export const Slice = createSlice({
  name: 'app',
  initialState: DEFAULT_APP,
  reducers: {
    setInitialLoadDone: (state) => ({
      ...state,
      isInitialLoad: false,
    }),
    setOpenLoginPupop: (state, action) => ({
      ...state,
      isOpenLoginPupop: action.payload,
    }),
    setProviders: (state, action) => ({
      ...state,
      sourceProvider: action.payload.source,
      destinationProvider: action.payload.destination,
    }),
    setIsRedirectAfterLogin: (state, action) => ({
      ...state,
      isRedirectAfterLogin: action.payload,
    }),
    setIsStartTransferAfterGo: (state, action) => ({
      ...state,
      isStartTransferAfterGo: action.payload,
    }),
  },
});

export const {
  setInitialLoadDone,
  setOpenLoginPupop,
  setIsRedirectAfterLogin,
  setIsStartTransferAfterGo,
  setProviders,
} = Slice.actions;

export const selectIsInitialLoad = (state: any) => state.app.isInitialLoad;
export const selectOpenLoginPupop = (state: any) => state.app.isOpenLoginPupop;
export const selectIsRedirectAfterLogin = (state: any) => state.app.isRedirectAfterLogin;
export const selectIsStartTransferAfterGo = (state: any) => state.app.isStartTransferAfterGo;
export const selectProviders = (state: any) => ({
  source: state.app.sourceProvider,
  destination: state.app.destinationProvider,
});

export default Slice.reducer;
