import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { providerInterface, openLoginPopupForProvider } from '../provider';
import { componentStyle } from './playlist.styles';

const PlayList: React.FC<{ sourceApp: providerInterface }> = ({ sourceApp }) => {
  const classes = componentStyle();

  const handleLoginClick = () => {
    openLoginPopupForProvider(sourceApp.id);
  };

  return (
    <div className={classes.root}>
      <Typography>
        Connect to {sourceApp.title}
      </Typography>
      <br />
      <Button onClick={handleLoginClick} variant="contained" color="primary">
        Connect
        </Button>
    </div>
  );
};

export default PlayList;
