import { useState } from 'react';
import type { MessageType } from './snacker.types';
import TransferSnacker from './snacker.component';

const useSnacker = () => {
  const [snacks, setMessages] = useState<MessageType[]>([]);
  const showSnack = (message: MessageType) => {
    setMessages([message, ...snacks]);
  };
  const Snacker = TransferSnacker;
  return {
    Snacker,
    snacks,
    showSnack,
  };
};

export default useSnacker;
