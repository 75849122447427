import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { titlebarClass } from './playlist-title.styles';
import { ProviderAvatar, providerInterface } from '../provider';

const Main: React.FC<{
  isShowDisconnectButton: boolean,
  disconnect: () => void,
  provider: providerInterface
}> = ({ isShowDisconnectButton, disconnect, provider }) => {
  const classes = titlebarClass();

  return (
    <div className={classes.parent}>
      <Typography display="inline" variant="h4" gutterBottom>
        {provider.title}
      </Typography>
      {isShowDisconnectButton
        && <Button
          className={classes.titleBotton}
          variant="outlined"
          size="small"
          color="primary"
          onClick={disconnect}>
          Disconnect
        </Button>
      }
      <ProviderAvatar className={classes.theIcon} provider={provider} />
    </div>
  );
};

export default Main;
