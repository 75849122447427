import { createSlice } from '@reduxjs/toolkit';
import { User } from './slice.types';

const DEFAULT_USER = {
  loggedin: false,
  displayName: '',
  email: '',
  photoURL: '',
  providers: {},
  transfers: {},
};

export const Slice = createSlice({
  name: 'user',
  initialState: DEFAULT_USER,
  reducers: {
    login: (state, action) => ({
      ...state,
      ...action.payload,
      loggedin: true,
    }),
    logout: () => ({ ...DEFAULT_USER }),
    setProviders: (state, action) => ({
      ...state,
      providers: action.payload.providers || {},
    }),
    setTransfers: (state, action) => ({
      ...state,
      transfers: action.payload || {},
    }),
  },
});

export const {
  login, logout, setProviders, setTransfers,
} = Slice.actions;

export const selectUser = (state: { user: User }) => state.user;

export default Slice.reducer;
