import type { MouseEvent } from 'react';
import { useState } from 'react';
import type { DataType as Data, Order } from './track-table.types';

const useTrackTable = () => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('status');

  const handleRequestSort = (event: MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return {
    handleRequestSort,
    order,
    orderBy,
  };
};

export default useTrackTable;
