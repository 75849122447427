import { makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

export const optionStyle = makeStyles((theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  selected: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
}));

export const componentStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  paper: {
    width: 240,
    height: 240,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  paperMiddle: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 20,
    width: 70,
    height: 240,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  text: {
    display: 'inline-block',
    fontSize: '30rm',
    verticalAlign: 'middle',
  },
  control: {
    padding: theme.spacing(2),
  },
}));
export const PopupSignin = makeStyles(() => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
}));

export const selectApplication = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    verticalAlign: 'middle',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
