import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
      justifyContent: 'center',
      flexDirection: 'row',
    },
  },
  flexItem: {
    margin: 'auto 10px',
  },

}));
