import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useSelector } from 'react-redux';

import LinearProgress from '@material-ui/core/LinearProgress';

import { FireAuth } from '../../utils/firebase';
import { popUpAuthenticate } from './login.helpers';
import { selectIsInitialLoad } from '../../slices/app.slice';
import Styles from './login.styles';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    firebaseAuth: any;
  }
}

const Login: React.FC = () => {
  const isInitialLoad = useSelector(selectIsInitialLoad);
  const classes = Styles();
  const openPopupGoogle = () => popUpAuthenticate('google.com');
  const openPopupFacebook = () => popUpAuthenticate('facebook.com');
  const openPopupTwitter = () => popUpAuthenticate('twitter.com');

  window.firebaseAuth = FireAuth;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {isInitialLoad && (
          <div className={classes.authCheckerWrapper}>
            <p className={classes.authCheckerContent}>
              Checking...
            </p>
            <LinearProgress />
          </div>
        )}
        {!isInitialLoad && (
          <form className={classes.form} noValidate>
            <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={openPopupGoogle}>
              Sign In with Google
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={openPopupFacebook}
            >
              Sign In with Facebook
            </Button>
            <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={openPopupTwitter}>
              Sign In with Twitter
            </Button>
          </form>
        )}
      </div>
    </Container>
  );
};

export default Login;
