import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Fab } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { componentStyle as useStyles } from './main.styles';
import { PROVIDERS_LIST, ProviderSelect } from '../provider';
import type { providerInterface } from '../provider';
import { selectUser } from '../../slices/user.slice';
import {
  setOpenLoginPupop, setIsRedirectAfterLogin,
  selectProviders, setProviders, selectIsRedirectAfterLogin,
} from '../../slices/app.slice';

const Main: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const provider = useSelector(selectProviders);
  const isRedirectAfterLogin = useSelector(selectIsRedirectAfterLogin);
  const classes = useStyles();
  const history = useHistory();

  const isMobileScreen = useMediaQuery('(max-width:500px)');

  const [sourceApp, setSourceApp] = useState<providerInterface>(PROVIDERS_LIST[0]);
  const [lastSourceApp, setlastSourceApp] = useState<providerInterface>(PROVIDERS_LIST[0]);
  const [destinationApp, setDestinationApp] = useState<providerInterface>(PROVIDERS_LIST[1]);

  useEffect(() => {
    dispatch(setIsRedirectAfterLogin(false));
    dispatch(setProviders({ source: sourceApp, destination: destinationApp }));
  }, [dispatch, sourceApp, destinationApp]);

  useEffect(() => {
    if (sourceApp.id === destinationApp.id) {
      setDestinationApp(lastSourceApp);
      dispatch(setProviders({ source: sourceApp, destination: lastSourceApp }));
    } else {
      dispatch(setProviders({ source: sourceApp, destination: destinationApp }));
    }
    setlastSourceApp(sourceApp);
  }, [sourceApp, destinationApp, dispatch, lastSourceApp]);

  const goToSourcePage = useCallback(() => {
    dispatch(setIsRedirectAfterLogin(false));
    history.push(`/source/${provider.source.id}/transfer/${provider.destination.id}`);
  }, [history, dispatch, provider.destination.id, provider.source.id]);

  useEffect(() => {
    if (user.loggedin && isRedirectAfterLogin) {
      goToSourcePage();
    }
  }, [user.loggedin, goToSourcePage, isRedirectAfterLogin]);

  const goAction = () => {
    if (user.loggedin) {
      goToSourcePage();
    } else {
      dispatch(setIsRedirectAfterLogin(true));
      dispatch(setOpenLoginPupop(true));
    }
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Typography className={classes.text}>Transfer my playlist</Typography>
        <br />
        <Typography className={classes.text}> From </Typography>
        <ProviderSelect
          selected={sourceApp}
          setSelected={(item) => { setSourceApp(item); }}
          listOfApps={PROVIDERS_LIST} />
        {isMobileScreen && <br />}
        <Typography className={classes.text}> to </Typography>
        <ProviderSelect
          selected={destinationApp}
          setSelected={(item) => { setDestinationApp(item); }}
          listOfApps={PROVIDERS_LIST.filter((i) => i.id !== sourceApp.id)} />
        {isMobileScreen && <br />}
        <Fab aria-label="Action" color="primary" onClick={goAction}>
          <span>GO</span>
        </Fab>
      </div>
    </Fragment>
  );
};

export default Main;
