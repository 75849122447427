import React, { Fragment } from 'react';
import { Typography, Fab } from '@material-ui/core';
import { useStyles } from '../transfer.styles';
import { providerInterface, ProviderSelect } from '../../provider';
import useTransferToolbox from './toolbox.hooks';

const Main : React.FC<{
  sourceProvider: providerInterface,
  destinationProvider: providerInterface
}> = () => {
  const classes = useStyles();
  const {
    goAction, setLocalDestination,
    localDestination, listOfAvailableProviders, disable,
    Snacker, snacks,
  } = useTransferToolbox();
  return (
    <Fragment>
      <div className={classes.root}>
        <Typography className={classes.text}> Transfer to </Typography>
        <ProviderSelect
          selected={localDestination}
          setSelected={(item) => { setLocalDestination(item); }}
          listOfApps={listOfAvailableProviders} />
        <Fab disabled={disable} aria-label="Action" color="primary" onClick={goAction}>
          <span>GO</span>
        </Fab>
      </div>
      <Snacker snacks={snacks} />
    </Fragment>
  );
};

export default Main;
