import React, { Fragment } from 'react';
import {
  Grid, Button, LinearProgress,
} from '@material-ui/core';
import {
  ArrowBackIos,
} from '@material-ui/icons';
import type { Transfer } from '@playlist/types';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStyles } from './item.styles';
import { useTransferItem } from './item.hooks';
import TrackTable from './track-table';
import ItemDetail from './item-detail.component';
import { useTrack } from '../../../hooks/transfer.hooks';

const TransferDetail: React.FC<{ id: string; transfer: Transfer }> = ({ id, transfer }) => {
  const classes = useStyles();
  const {
    source, destination,
  } = useTransferItem({ transfer });
  const TrackOfTransfer = useTrack(id);
  const isDesktopScreen = useMediaQuery('(min-width:930px)');

  return (
    <Fragment>
      <Button
        variant="outlined"
        className={isDesktopScreen ? classes.backIoSDesktop : classes.backIoSMobile}
        component={Link}
        to='/transfer/report/'>
        <ArrowBackIos />
      </Button>
      <Grid container justify = "center" >
        <ItemDetail transferId={id} transfer={transfer} />
        { TrackOfTransfer.length > 0
          ? <div className={classes.rootWithoutPadding}>
            <TrackTable tracks={TrackOfTransfer} source={source} destination={destination} />
          </div>
          : <div className={classes.root}>
              <div className={classes.sectionMain}>
                <LinearProgress />
              </div>
          </div>
      }
      </Grid>
    </Fragment>
  );
};

export default TransferDetail;
