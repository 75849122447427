import { TrackLog, Transfer } from 'types/typing';

export default function calcPercentage(
  numberOfTransferedTrack: number,
  totalTracks: number,
) {
  const transfered = numberOfTransferedTrack + 1;
  const variant = transfered / totalTracks;
  const percent = variant * 100;
  return Math.round(percent);
}

export function calcFinish(tracks: TrackLog[]) {
  const searched = tracks.filter(({ isSearched }) => isSearched);
  const transfered = tracks.filter(({ isTransfered }) => isTransfered);
  const errors = tracks.filter(({ error }) => error);
  return searched.length >= transfered.length + errors.length;
}

export function getlinkToTransfer(transferId: string) {
  return `/transfer/report/${transferId}`;
}

export function sortAndFilterTransfer(transfers: { [x: string]: Transfer; }) {
  const keys = Object.keys(transfers);
  const transfersWithId = keys.map((id) => {
    const transfer = transfers[id];
    return { ...transfer, id };
  });
  const finished = transfersWithId
    .filter(({ isCompleted }) => isCompleted)
    .sort((first, second) => Number(second.creationDate) - Number(first.creationDate));
  const notFinished = transfersWithId
    .filter(({ isCompleted }) => !isCompleted)
    .sort((first, second) => Number(second.creationDate) - Number(first.creationDate));
  return notFinished.concat(finished);
}
