import React, { useState } from 'react';
import {
} from '@material-ui/core';

const useToolabrMenu = () => {
  const [openLogoutMenu, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return {
    openLogoutMenu,
    handleOpen,
    handleClose,
    anchorEl,
    setAnchorEl,
  };
};

export default useToolabrMenu;
