import { PROVIDERS_LIST, providerInterface } from '../provider';

export const getProviderLink = (id: string) => `/playlist/${id}`;

// eslint-disable-next-line no-unused-vars
export const getUserProviders: (user: any) => providerInterface[] = (user) => {
  const userProviders = Object.keys(user.providers);
  return PROVIDERS_LIST
    .filter((provider) => userProviders.includes(provider.id))
    .map((provider) => ({ ...provider, link: getProviderLink(provider.id) }));
};
