import React from 'react';
import Grid from '@material-ui/core/Grid';
import type { Transfer } from 'types/typing';
import { ArrowForward } from '@material-ui/icons';
import {
  Avatar, Paper, Tooltip, Typography,
} from '@material-ui/core';
import { useStyles, useTransferItemStyle, useIconStyle } from './item.styles';
import { ProviderAvatar } from '../../provider';
import CircularProgressWithLabel from '../transfer-progress.component';
import { useTransferItem, useTrackItem } from './item.hooks';

const TransferItem: React.FC<{
  transferId: string;
  transfer: Transfer;
}> = function TransferItemComponent({ transferId, transfer }) {
  const styles = useStyles();
  const classes = useTransferItemStyle();
  const iconClasses = useIconStyle();
  const {
    source, destination,
  } = useTransferItem({ transfer });
  const {
    failRate, successRate, percentCompelete,
  } = useTrackItem({ transferId, totalTracks: transfer.totalTracks });
  return (
    <Grid
      container
      component={Paper}
      className={classes.item}
      variant="outlined"
      justify="space-between"
      alignItems="center">

        <div className={classes.tab}>
            <Grid className={classes.tabTitle} container spacing={1}>
            <Grid className={classes.noPadding} item xs={4} sm={4}>
              {source
                && <Tooltip title={`click to open playlist in ${source.id}`} aria-label="add">
                      <a target="_blank" href={`${source.playlistBaseLink}/${transfer.sourcePlaylistId}`}>
                        <ProviderAvatar className={styles.icon} provider={source} />
                      </a>
                    </Tooltip>
              }
            </Grid>
            <Grid className={classes.arrowMiddle} item xs={4} sm={4}>
              <ArrowForward />
            </Grid>
            <Grid className={classes.noPadding} item xs={4} sm={4}>
              { destination
                && <Tooltip title={`click to open playlist in ${destination.id}`} aria-label="add">
                      <a target="_blank" href={`${destination.playlistBaseLink}/${transfer.destinationPlaylistId}`}>
                        <ProviderAvatar className={styles.icon} provider={destination} />
                      </a>
                    </Tooltip>
              }
            </Grid>
          </Grid>
        </div>

      <Grid className={classes.name} item xs>
        <Typography> {transfer.name} </Typography>
      </Grid>

      <Grid item>
        <Tooltip title='successfully transfered tracks' aria-label="successfully transfered tracks">
          <Avatar className={iconClasses.successIcon}>{successRate}</Avatar>
        </Tooltip>
        <Tooltip title='not found tracks' aria-label="not found tracks">
          <Avatar className={iconClasses.failIcon}>{failRate}</Avatar>
        </Tooltip>
        <Tooltip title='not found tracks' aria-label="not found tracks">
          <CircularProgressWithLabel className={iconClasses.progresIcon} value={percentCompelete} />
        </Tooltip>
      </Grid>
    </Grid>

  );
};

export default TransferItem;
