// eslint-disable-next-line import/prefer-default-export
export const providersList = [
  {
    id: 'spotify',
    icon: '',
    title: 'Spotify',
    playlistBaseLink: 'https://open.spotify.com/playlist',
    trackBaseLink: 'https://open.spotify.com/track',
  },
  {
    id: 'deezer',
    icon: 'statics/deezer.png',
    title: 'Deezer',
    playlistBaseLink: 'https://www.deezer.com/us/playlist',
    trackBaseLink: 'https://www.deezer.com/us/track',
  },
];
