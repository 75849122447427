import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import type { MessageType } from './snacker.types';

const TransferSnacker: React.FC<{ snacks: MessageType[] }> = ({ snacks }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessages] = useState<MessageType>();
  useEffect(() => {
    if (snacks.length > 0) {
      setMessages(snacks[0]);
      setOpen(true);
    }
  }, [snacks]);

  const handleClose = () => {
    setOpen(false);
  };
  return (message
    ? <Snackbar open={open}
      onClose={handleClose}
      autoHideDuration={6000}
    >
      <Alert onClose={handleClose} severity={message.type} >
        {message.title && <AlertTitle>{message.title}</AlertTitle>}
        {message.text}
        {message.link && <Link to={message.link}><strong>check out</strong></Link>}
      </Alert>
    </Snackbar>
    : <></>
  );
};

export default TransferSnacker;
