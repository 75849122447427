import React from 'react';
import { CssBaseline } from '@material-ui/core';
import Styles from './side-menu.styles';
import MenuToolbar from './side-menu-toolbar.component';
import MenuDrawer from './side-menu-drawer.component';

const SideMenu: React.FC = () => {
  const classes = Styles();
  const [isOpenDrawer, setOpenDrawer] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MenuToolbar
        Title="Transfer playlist"
        open={isOpenDrawer}
        handleDrawerOpen={handleDrawerOpen} />
      <MenuDrawer
        open={isOpenDrawer}
        handleDrawerClose={handleDrawerClose} />
    </div>
  );
};

export default SideMenu;
