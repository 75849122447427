// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';

import Config from '@playlist/config';

// Initialize Firebase
const Fire = firebase.initializeApp(Config.web.firebase);
Fire.analytics();

const FireAuth = firebase.auth;
const DB = Fire.firestore();
const Database = firebase.database();

export {
  Fire,
  FireAuth,
  DB,
  Database,
};
