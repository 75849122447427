import { FireAuth } from '../../utils/firebase';

const getProviderForProviderId = (providerId: string) => {
  if (providerId === 'google.com') {
    const provider = new FireAuth.GoogleAuthProvider();
    return provider;
  } if (providerId === 'facebook.com') {
    const provider = new FireAuth.FacebookAuthProvider();
    // provider.addScope('user_birthday');
    return provider;
  } if (providerId === 'twitter.com') {
    return new FireAuth.TwitterAuthProvider();
  }
  throw new Error(`No provider implemented for ${providerId}`);
};

// eslint-disable-next-line import/prefer-default-export
export const popUpAuthenticate = (providerId: string) => {
  const provider = getProviderForProviderId(providerId);

  FireAuth()
    .signInWithPopup(provider)
    .then()
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      // The email of the user's account used.
      const { email } = error;
      // The firebase.auth.AuthCredential type that was used.
      const { credential } = error;
      if (errorCode === 'auth/account-exists-with-different-credential') {
        FireAuth()
          .fetchSignInMethodsForEmail(email)
          .then((methods) => {
            const authProvider = getProviderForProviderId(methods[0]);
            FireAuth()
              .signInWithPopup(authProvider)
              .then((retryResult) => {
                retryResult.user?.linkWithCredential(credential);
                // .linkAndRetrieveDataWithCredential
              });
          });
      }
    });
};
