import { useEffect } from 'react';
import { User } from 'firebase';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout, setProviders } from '../slices/user.slice';
import { FireAuth, Database } from '../utils/firebase';
import { registerUser, unRegisterUser } from '../utils/auth';
import { selectIsInitialLoad, setInitialLoadDone } from '../slices/app.slice';

// eslint-disable-next-line import/prefer-default-export
export const useAuth = () => {
  const dispatch = useDispatch();
  const isInitialLoad = useSelector(selectIsInitialLoad);

  useEffect(() => {
    const clearListener = FireAuth().onAuthStateChanged(async (fireUser) => {
      function processUserSignout() {
        unRegisterUser().catch((err) => {
          if (err.response?.status !== 401) {
            // console.log('error', err);
          }
        });
        dispatch(logout());
      }

      function subscribeUserProvider(user: User) {
        const authRef = Database.ref().child(`providers/${user.uid}`);
        authRef.on('value', (data) => {
          const action = {
            providers: data.val(),
          };
          dispatch(setProviders(action));
        });
      }

      async function processUserLogin(user: User) {
        const action = {
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        };

        const idToken = await FireAuth()
          ?.currentUser?.getIdToken()
          .catch((err) => {
            throw err;
          });

        // @ts-ignore
        const authRes = await registerUser(idToken).catch((err) => {
          FireAuth().signOut();
          throw err;
        });

        if (authRes) {
          dispatch(login(action));
        }
      }

      if (fireUser !== null) {
        subscribeUserProvider(fireUser);
        await processUserLogin(fireUser);
        dispatch(setInitialLoadDone());
      } else if (!isInitialLoad) {
        processUserSignout();
      } else {
        dispatch(setInitialLoadDone());
      }
    });
    return () => {
      clearListener();
    };
  },
  [isInitialLoad, dispatch]);
};
