import { makeStyles, createStyles } from '@material-ui/core';

export default makeStyles(() => createStyles({
  cover: {
    width: 50,
    height: 50,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
}));
