import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import { Tooltip, useMediaQuery } from '@material-ui/core';
import { useTableStyles } from './playlist-table.styles';
import EnhancedTableHead from './playlist-table-head.component';
import EnhancedTableToolbar from './playlist-table-toolbar.component';
import type { DataType as Data } from './playlist-table.types';
import usePlaylistTable from './playlist-table.hooks';
import { getComparator, stableSort } from './playlist-table.helpers';

const EnhancedTable: React.FC<{ playlists: Data[] }> = ({ playlists }) => {
  const {
    clearSelection, handleClick, handleRequestSort, handleSelectAllClick,
    isSelected, order, orderBy, selectedPlaylistIds,
  } = usePlaylistTable(playlists);
  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const classes = useTableStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selectedPlaylistIds.length}
          clearSelection={clearSelection}
        />
        <TableContainer className={classes.tableBodyScroll}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selectedPlaylistIds.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={playlists.length}
            />
            <TableBody>
              {stableSort(playlists, getComparator(order, orderBy))
                .map((row: any, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => {
                        handleClick(event, row);
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell
                        className={isMobileScreen ? classes.tableName : ''}
                        component="th"
                        id={labelId} padding="none">
                        {isMobileScreen
                          && <Tooltip title={row.name}>
                            <span>{`${row.name}`.substr(0, 20)}</span>
                          </Tooltip>
                        }
                        {!isMobileScreen && row.name}
                      </TableCell>
                      <TableCell align="right">{row.totalTracks}</TableCell>
                      <TableCell align="right">
                        <Avatar alt="Remy Sharp" src={row.picture} className={classes.albumart} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};
export default EnhancedTable;
