import React from 'react';
import {
  MenuItem, Avatar,
} from '@material-ui/core';

const Logout: React.FC<{
  src: string;
  // eslint-disable-next-line no-unused-vars
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
}> = ({ src, handleOpen }) => (
    <MenuItem onClick={handleOpen}>
      <Avatar
        onClick={handleOpen}
        alt="user profile image" src={src} />
    </MenuItem>
);

export default Logout;
