/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { providerInterface, PROVIDERS_LIST } from '../provider';
import type { DataType } from '../playlist-table';
import { selectUser } from '../../slices/user.slice';
import * as http from '../../utils/http-client';
import { selectSelectedPlaylistIds, setSelectedPlaylistIds } from '../../slices/playlist.slice';
import { setProviders } from '../../slices/app.slice';
import { useSnacker } from '../snacker';

const usePlaylist = () => {
  const { sourceAppId, destinationAppId } = useParams<{
    sourceAppId: string, destinationAppId: string
  }>();
  const providerItem = PROVIDERS_LIST.find((i) => i.id === sourceAppId) || PROVIDERS_LIST[0];
  const [playlists, setPlaylists] = useState<DataType[]>([]);
  const [sourceProvider, setSourceProvider] = useState<providerInterface>(providerItem);
  const [destinationProvider, setDestinationProvider] = useState<providerInterface>(providerItem);
  const [hasPlaylist, setHasPlaylist] = useState<boolean>(false);
  const [hasProvider, setHasProvider] = useState<boolean>(false);
  const [hasCurrentTransfer, setHasCurrentTransfer] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const { snacks, Snacker, showSnack } = useSnacker();
  // TODO: move it to slicers
  const selectedPlaylists = useSelector(selectSelectedPlaylistIds);

  const deleteProvider = useCallback(() => {
    http.get('provider/disconnect', { params: { provider: sourceProvider.id } });
  }, [sourceProvider.id]);

  const dispatch = useDispatch();

  // update playlist from user.provider
  useEffect(() => {
    const provider = user.providers[sourceProvider.id];
    if (provider?.playlists) {
      const playlistsForTable = provider.playlists
        .filter((plst) => plst.totalTracks)
        .map(({
          id, name, totalTracks, picture,
        }) => ({
          id, name, totalTracks, picture,
        }));
      setHasProvider(true);
      setHasPlaylist(true);
      setPlaylists(playlistsForTable);
    } else {
      setHasProvider(false);
      setHasPlaylist(false);
      setPlaylists([]);
      showSnack({
        title: 'desconnect', text: 'provider is discunnected', type: 'warning',
      });
    }
  }, [sourceProvider.id, user.providers]);

  // update source provider on page start (because get this variable from proms)
  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(user.providers, sourceProvider.id)) {
      http.get('playlist/refresh', { params: { provider: sourceProvider.id } }).catch();
    }
    const item = PROVIDERS_LIST.find((i) => i.id === sourceAppId) || PROVIDERS_LIST[0];
    setSourceProvider(item);
  }, [sourceAppId, sourceProvider.id, user.providers]);

  // update transfer log to null on destination app id change (when page url changes)
  useEffect(() => {
    const item = PROVIDERS_LIST.find((i) => i.id === destinationAppId);
    if (item) {
      dispatch(setSelectedPlaylistIds([]));
      setDestinationProvider(item);
    }
  }, [destinationAppId, dispatch]);

  // update transfer log to null on source app id change (when page url changes)
  useEffect(() => {
    const item = PROVIDERS_LIST.find((i) => i.id === sourceAppId);
    if (item) {
      dispatch(setSelectedPlaylistIds([]));
      setSourceProvider(item);
    }
  }, [dispatch, sourceAppId]);

  // update providers when apps change
  useEffect(() => {
    if (sourceProvider.id === destinationProvider.id) {
      const destination = PROVIDERS_LIST.find((item) => item.id !== sourceProvider.id);
      dispatch(setProviders({ source: sourceProvider, destination }));
    } else {
      dispatch(setProviders({ source: sourceProvider, destination: destinationProvider }));
    }
  }, [dispatch, sourceProvider, destinationProvider]);

  return {
    playlists,
    destinationProvider,
    sourceProvider,
    selectedPlaylists,
    hasPlaylist,
    hasProvider,
    deleteProvider,
    Snacker,
    snacks,
  };
};

export default usePlaylist;
