import type { MouseEvent, ChangeEvent } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedPlaylistIds, setSelectedPlaylistIds } from '../../slices/playlist.slice';
import type { DataType as Data, Order } from './playlist-table.types';

export default (playlists: Data[]) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('totalTracks');
  const selectedPlaylistIds = useSelector(selectSelectedPlaylistIds);
  const dispatch = useDispatch();

  const callForSetSelectedRows = (newPlaylist: any) => {
    dispatch(setSelectedPlaylistIds(newPlaylist));
  };

  const handleRequestSort = (event: MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = playlists.map((i: any) => i.id);
      callForSetSelectedRows(newSelecteds);
    } else {
      callForSetSelectedRows([]);
    }
  };

  const handleClick = (event: MouseEvent<unknown>, row: any) => {
    const selectedIndex = selectedPlaylistIds.indexOf(row.id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPlaylistIds, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPlaylistIds.slice(1));
    } else if (selectedIndex === selectedPlaylistIds.length - 1) {
      newSelected = newSelected.concat(selectedPlaylistIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPlaylistIds.slice(0, selectedIndex),
        selectedPlaylistIds.slice(selectedIndex + 1),
      );
    }

    callForSetSelectedRows(newSelected);
  };

  const isSelected = (id: string) => selectedPlaylistIds.indexOf(id) !== -1;

  const clearSelection = () => {
    dispatch(setSelectedPlaylistIds([]));
  };

  return {
    clearSelection,
    isSelected,
    handleClick,
    handleRequestSort,
    handleSelectAllClick,
    order,
    orderBy,
    selectedPlaylistIds,
  };
};
