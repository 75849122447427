import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Main from '../components/main/main.routers';
import FourOFour from '../components/404';

const Root = () => (
    <Switch>
      <Route path="/" component={Main} />
      <Route component={FourOFour} />
    </Switch>
);

export default Root;
