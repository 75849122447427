import { useEffect, useState } from 'react';
import type { User } from 'firebase';
import { FireAuth } from '../utils/firebase';

const useUser = () => {
  const [user, setUser] = useState<User | null>(null);
  useEffect(() => {
    const clearListener = FireAuth().onAuthStateChanged(async (_user) => {
      setUser(_user);
    });
    return () => {
      clearListener();
    };
  }, []);
  return user;
};

export default useUser;
