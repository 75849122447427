// eslint-disable-next-line import/prefer-default-export
export const openLoginPopupForProvider = (providerTitle: string) => {
  window.open(
    `/api/${providerTitle}/login/redirect`,
    `${providerTitle}Login`,
    `scrollbars=no,
    resizable=no,
    status=no,
    location=no,
    toolbar=no,
    menubar=no,
    width=${window.innerWidth / 2},
    height=${window.innerHeight / 2},
    top=${window.innerHeight / 4},
    left=${window.innerWidth / 4}`,
  );
};
