import React, {
  Fragment, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Transfer } from 'types/typing';
import { selectUser } from '../../slices/user.slice';
import TransferItemDetails from './item/details.component';

const TransferDetails: React.FC<{}> = () => {
  const user = useSelector(selectUser);
  const [detailTransfer, setDetailTransfer] = useState<Transfer>();
  const { id } = useParams<{ id: string }>();
  const transfers = useMemo(() => Object.values(user.transfers) || [], [id, user.transfers]);
  const transferByDate = useMemo(() => transfers?.find((t) => `${t.creationDate}` === id),
    [id, transfers]);

  // TODO: should handle all implementation here
  // the detail - track component should be only tracks

  // get a single transfer by id or date
  useEffect(() => {
    const transferById = user.transfers[id];
    if (transferById) {
      setDetailTransfer(transferById);
    } else if (transferByDate) {
      setDetailTransfer(transferByDate);
    }
  }, [user.transfers]);

  return (
    <Fragment>
      { detailTransfer
        ? <TransferItemDetails id={id} transfer={detailTransfer} />
        : <div> nothing to show </div>
      }
    </Fragment>
  );
};

export default TransferDetails;
