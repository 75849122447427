import React from 'react';
import clsx from 'clsx';
import {
  Toolbar, IconButton, AppBar, Button, ThemeProvider,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Styles, { themeOverrided } from './side-menu.styles';
import { selectUser } from '../../slices/user.slice';
import { setOpenLoginPupop } from '../../slices/app.slice';
import { LogoutAvatar, LogoutMenu } from '../logout';
import useToolbarMenu from './toolbar.hooks';

interface IProps {
  Title: string;
  open: boolean;
  handleDrawerOpen: () => void;
}
const MenuToolbar: React.FC<IProps> = ({ Title, open, handleDrawerOpen }) => {
  const dispatch = useDispatch();
  const openLoginPopup = () => {
    dispatch(setOpenLoginPupop(true));
  };
  const {
    handleOpen, anchorEl, handleClose, openLogoutMenu,
  } = useToolbarMenu();
  const classes = Styles();
  const user = useSelector(selectUser);
  return (
    <ThemeProvider theme={themeOverrided}>

      <AppBar position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
          <MenuIcon />
          </IconButton>

          <Button component={Link} color="inherit" to="/">
              {Title}
          </Button>

          <p><sub>(beta)</sub></p>

          <div className={classes.rightButtonTollbar}>

            {user.loggedin
              ? <LogoutAvatar src={user.photoURL} handleOpen={handleOpen} />
              : <Button color="inherit" onClick={openLoginPopup}>
                  Login
                </Button>

            }

          </div>
        </Toolbar>
      </AppBar>
      <LogoutMenu anchorEl={anchorEl} handleClose={handleClose} open={openLogoutMenu} />
    </ThemeProvider>
  );
};
export default MenuToolbar;
