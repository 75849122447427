import { useEffect, useMemo, useState } from 'react';
import type { Transfer } from '@playlist/types';
import { useTrack } from '../../../hooks/transfer.hooks';
import { providerInterface, PROVIDERS_LIST } from '../../provider';
import {
  convertTrackLogs, getPercentage, getNotTransferdTracks, getTransferdTracks,
} from './item.helpers';

export function useTransferItem({ transfer }: { transfer: Transfer }) {
  const [successRate, setSuccessRate] = useState<number>(0);
  const [failRate, setFailRate] = useState<number>(0);
  const [percentCompelete, setPercentCompelete] = useState<number>(0);

  const TrackOfTransfer = useMemo(() => convertTrackLogs(transfer.tracks), [transfer]);

  useEffect(() => {
    const numOfTransfered = transfer.successTracksCount || 0;
    const numOfNotFound = transfer.failedTracksCount || 0;
    const percent = getPercentage(numOfTransfered, transfer.totalTracks);
    setSuccessRate(numOfTransfered);
    setFailRate(numOfNotFound);
    setPercentCompelete(percent);
  }, [transfer]);

  const source = useMemo<providerInterface | undefined>(
    () => PROVIDERS_LIST.find((pr) => pr.id === transfer.source),
    [transfer.source],
  );
  const destination = useMemo<providerInterface | undefined>(
    () => PROVIDERS_LIST.find((pr) => pr.id === transfer.destination),
    [transfer.destination],
  );

  return {
    successRate,
    failRate,
    percentCompelete,
    source,
    destination,
    TrackOfTransfer,
  };
}

export function useTrackItem({ transferId, totalTracks }: {
  transferId: string;
  totalTracks: number;
}) {
  const tracks = useTrack(transferId);
  const [successRate, setSuccessRate] = useState<number>(0);
  const [failRate, setFailRate] = useState<number>(0);
  const [percentCompelete, setPercentCompelete] = useState<number>(0);

  useEffect(() => {
    const transferedLen = getTransferdTracks(tracks);
    setSuccessRate(transferedLen);
    setFailRate(getNotTransferdTracks(tracks));
    const percent = getPercentage(transferedLen, totalTracks);
    setPercentCompelete(percent);
  }, [tracks]);

  return {
    successRate, failRate, percentCompelete,
  };
}
