import React from 'react';
import {
  MenuItem, Popover,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FireAuth } from '../../utils/firebase';

const LogoutMenu: React.FC<{
  handleClose: () => void;
  anchorEl: null | HTMLElement;
  open: boolean;
}> = ({
  handleClose, anchorEl,
}) => {
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleSignout = () => {
    FireAuth().signOut();
    history.push('/');
  };

  // TODO: use different id for mobile and desktop
  const menuId = 'AuthMenuDesktop';

  return (
    <Popover
      anchorEl={anchorEl}
      id={menuId}
      open={open}
      onClose={handleClose}
      disableRestoreFocus

      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem onClick={handleSignout}>Logout</MenuItem>
    </Popover>
  );
};

export default LogoutMenu;
