import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Playlist from './playlist.component';
import FourOFour from '../404';

const Root = () => (
    <Switch>
      <Route path="/playlist" component={Playlist}>
        <Route path="/playlist/:sourceAppId">
          <Playlist />
        </Route>
      </Route>
      <Route path="/source" component={Playlist}>
        <Route exact path="/source/:sourceAppId/transfer/:destinationAppId">
          <Playlist />
        </Route>
      </Route>
      <Route component={FourOFour} />
    </Switch>
);

export default Root;
