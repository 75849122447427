import {
  createStyles, lighten, makeStyles, Theme,
} from '@material-ui/core/styles';

export const useToolbarStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

export const selectApplication = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    verticalAlign: 'middle',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    textAlign: 'right',
    margin: '20px auto',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  paperMiddle: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 20,
    width: 70,
    height: 240,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  text: {
    display: 'inline-block',
    fontSize: '30rm',
    verticalAlign: 'middle',
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export const useStylesComponent = makeStyles(() => createStyles({
  root: {
    textAlign: 'center',
    marginTop: '50px auto',
  },
  text: {
    display: 'inline-block',
    fontSize: '30rm',
    verticalAlign: 'middle',
  },
}));
