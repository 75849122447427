import { createSlice } from '@reduxjs/toolkit';

const Slice = createSlice({
  name: 'playlist',
  initialState: { selectedPlaylistIds: [] },
  reducers: {
    setSelectedPlaylistIds: (state, action) => ({
      ...state,
      selectedPlaylistIds: action.payload,
    }),
  },
});

export const { setSelectedPlaylistIds } = Slice.actions;

// eslint-disable-next-line max-len
export const selectSelectedPlaylistIds = (state: {
  playlist: {
    selectedPlaylistIds: string[],
  }
}) => state.playlist.selectedPlaylistIds;

export default Slice.reducer;
