import { grey } from '@material-ui/core/colors';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    maxWidth: 660,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  rootWithoutPadding: {
    width: '100%',
    maxWidth: 660,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  rootList: {
    width: '100%',
    maxHeight: 460,
    overflow: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  sectionMain: {
    margin: theme.spacing(3, 2),
  },
  sectionHeaderLink: {
    margin: theme.spacing(1, 2, 0, 2),
  },
  sectionInfo: {
    margin: theme.spacing(2),
    textAlign: 'right',
  },
  sectionLink: {
    margin: theme.spacing(3, 1, 1),
  },
  listItemPending: {
    overflowWrap: 'anywhere',
    color: '#5e6c84',
  },
  listItemSuccess: {
    overflowWrap: 'anywhere',
    color: '#61bd4f',
  },
  listItemFailed: {
    overflowWrap: 'anywhere',
    color: '#ec9488',
  },
  listAction: {
    right: '-20px',
  },
  textLink: {
    color: 'inherit',
  },
  centerText: {
    textAlign: 'center',
  },
  icon: {
    display: 'inline-flex',
    width: '30px',
    height: '30px',
  },
  backIoSDesktop: {
    position: 'absolute',
  },
  backIoSMobile: {
    position: 'initial',
    marginBottom: '10px',
  },
}));

export const useIconStyle = makeStyles(() => createStyles({
  icon: {
    display: 'inline-flex',
    width: '30px',
    height: '30px',
    // margin: '0 10px',
  },
  successIcon: {
    margin: '0 10px 0 10px',
    display: 'inline-flex',
    backgroundColor: '#73f783',
    fontSize: '14px',
    width: '30px',
    height: '30px',
  },
  failIcon: {
    margin: '0 10px 0 10px',
    display: 'inline-flex',
    backgroundColor: '#f77373',
    fontSize: '14px',
    width: '30px',
    height: '30px',
  },
  progresIcon: {
    margin: '0 10px 0 10px',
    verticalAlign: 'middle',
  },
}));

const tabHeight = 42;
export const useTransferItemStyle = makeStyles((theme: Theme) => createStyles({
  item: {
    width: '100%',
    maxWidth: 660,
    margin: `${tabHeight}px auto 10px auto`,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(0),
    '&::before': {
      width: '152px',
      left: '-1px',
      height: `${tabHeight}px `,
      top: `-${tabHeight}px`,
      background: 'white',
      content: '" "',
      display: 'block',
      position: 'absolute',
      border: '1px solid',
      borderColor: grey[300],
      borderBottom: '1px solid white',
      borderTopRightRadius: theme.spacing(1),
      borderTopLeftRadius: theme.spacing(1),
    },
  },
  tab: {
    position: 'absolute',
    display: 'block',
    top: '-35px',
    // left: '11px',
  },
  name: {
    // https://www.w3schools.com/cssref/css3_pr_text-overflow.asp
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: '10px',
  },
  noPadding: {
    padding: 0,
  },
  tabTitle: {
    paddingLeft: '10px',
  },
  arrowMiddle: {
    padding: '6px 0 0 10px',
  },
  progressBar: {
    width: '100%',
    margin: '10px',
  },
}));
