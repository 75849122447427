import React from 'react';
import { Typography } from '@material-ui/core';

import Styles from './copyright.styles';

export default function Copyright({ title }: { title: string; }) {
  const classes = Styles();

  return (
    <Typography variant="body2" color="textSecondary" align="center" className={classes.flexContainer}>
      <a href='/' className={classes.flexItem}>Home</a>
      <a href='/blog/faq' className={classes.flexItem}>FAQ</a>
      <a href='/blog/terms-of-service' className={classes.flexItem}>Terms of service</a>
      <a href='/blog/privacy-policy' className={classes.flexItem}>Privacy policy</a>
      <span className={classes.flexItem}>Copyright © {title} 2021.</span>
    </Typography>
  );
}
