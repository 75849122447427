import React from 'react';

const FourOFour: React.FC = () => (
    <div>
      <h2>NOT FOUND [404]</h2>
      <p> We are so sorry for showing this page. </p>
      <p> The page that you are trying to access, does not exist. </p>
      <p> Please, use navigation buttons on the side menu to navigate through the website. </p>
    </div>
);

export default FourOFour;
