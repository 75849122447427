import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import LoginPage from '../login';

const SigninDialog: React.FC<{
  // eslint-disable-next-line no-unused-vars
  open: boolean, setOpen: (value: boolean) => void
}> = ({ open, setOpen }) => {
  // eslint-disable-next-line no-unused-vars
  const handleClose = (value: string) => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <LoginPage />
    </Dialog>
  );
};
export default SigninDialog;
