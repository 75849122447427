import React, { Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import PlaylistTitle from '../playlist-title';
import PlaylistTable from '../playlist-table';
import { SingleOptionTransfer } from '../transfer';
import PlaylistLogin from './playlist-login.component';
import usePlaylist from './playlist.hooks';
import Styles from './playlist.styles';

const PlaylistComponent = () => {
  const classes = Styles();
  const {
    playlists, deleteProvider, sourceProvider, destinationProvider,
    hasPlaylist,
  } = usePlaylist();

  return (
    <Fragment>
        <PlaylistTitle
          isShowDisconnectButton={playlists.length > 0}
          disconnect={deleteProvider}
          provider={sourceProvider}
        />
        <Divider
          className={classes.divider}
          variant='middle' />
        {hasPlaylist
          ? <Fragment>
              <PlaylistTable playlists={playlists} />
              <SingleOptionTransfer
                sourceProvider={sourceProvider}
                destinationProvider={destinationProvider}
              />
            </Fragment>
          : <PlaylistLogin sourceApp={sourceProvider} />
        }
    </Fragment>
  );
};

export default PlaylistComponent;
