import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/user.slice';
import TransferItemComplete from './item/item-list-complete.component';
import TransferItem from './item/item-list.component';
import { sortAndFilterTransfer } from './transfer.helpers';

const TransferPage: React.FC<{}> = () => {
  const user = useSelector(selectUser);
  const alteredTransfers = useMemo(() => sortAndFilterTransfer(user.transfers), [user.transfers]);

  return (
    <Grid container justify="center">
      {alteredTransfers.map((transfer) => (
        transfer.isCompleted
          ? <TransferItemComplete
            key={transfer.id}
            transferId={transfer.id}
            transfer={transfer} />
          : <TransferItem
            key={transfer.id}
            transferId={transfer.id}
            transfer={transfer} />
      ))}
    </Grid>
  );
};

export default TransferPage;
