import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { selectUser } from '../../slices/user.slice';

import Main from './main.component';
import Dashboard from '../dashboard';
import Login from '../login';
import FourOFour from '../404';
import { PlaylistRoutes } from '../playlist';
import TransferRoutes from '../transfer';

const Root = () => {
  const user = useSelector(selectUser);
  return (
    <Router>
      <Dashboard>
        {user.loggedin && (
          <Switch>
            <Route path="/" exact component={Main} />
            <Route path="/source" component={PlaylistRoutes} />
            <Route path="/playlist" component={PlaylistRoutes} />
            <Route path="/transfer" component={TransferRoutes} />
            <Route component={FourOFour} />
          </Switch>
        )}
        {!user.loggedin && (
          <Switch>
            <Route path="/" exact component={Main} />
            <Route component={Login} />
          </Switch>
        )}
      </Dashboard>
    </Router>
  );
};

export default Root;
