import React, { useMemo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TrackLog } from 'types/typing';
import { useTableStyles, useTableCellStyles } from './track-table.styles';
import EnhancedTableHead from './track-table-head.component';
import useTrackTable from './track-table.hooks';
import {
  getComparator, stableSort, directDestinationTrackLink, directSourceTrackLink,
} from './track-table.helpers';
import { TransferTrackStatusText } from '../item.helpers';
import type { Data, HeadCell } from './track-table.types';
import { providerInterface } from '../../../provider';
import { useStyles } from '../item.styles';

// eslint-disable-next-line no-unused-vars
const EnhancedTable: React.FC<{
  tracks: TrackLog[]; source?: providerInterface; destination?: providerInterface;
}> = ({ tracks, source, destination }) => {
  // -- head
  const headCells = useMemo<HeadCell[]>(() => ([
    {
      id: 'name', numeric: false, disablePadding: true, label: source?.id || 'source',
    },
    {
      id: 'status', numeric: false, disablePadding: true, label: 'Status',
    },
    {
      id: 'destinationTrackId', numeric: false, disablePadding: false, label: destination?.id || 'destination',
    },
  ]), [source, destination]);
  // -- body
  const rowDataTracks = tracks.map((track) => {
    const status = TransferTrackStatusText(track);
    const { name, sourceTrackId, destinationTrackId } = track;
    return {
      name, status, sourceTrackId, destinationTrackId: destinationTrackId || '',
    };
  });

  const {
    handleRequestSort, order, orderBy,
  } = useTrackTable();
  const classes = useTableStyles();
  const cellClasses = useTableCellStyles();
  const classesItem = useStyles();

  const TransferTrackStatusClass = (status: string) => {
    switch (status) {
      case 'pending':
        return classesItem.listItemPending;
      case 'success':
        return classesItem.listItemSuccess;
      case 'failed':
        return classesItem.listItemFailed;
      default:
        return classesItem.listItemFailed;
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableBodyScroll}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={() => { }}
              onRequestSort={handleRequestSort}
              rowCount={rowDataTracks.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(rowDataTracks, getComparator(order, orderBy))
                .map((row: Data, index) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    className={TransferTrackStatusClass(row.status)}
                  >
                    <TableCell className={cellClasses.inherit} align="center">
                      <a target="_blank" className={cellClasses.inherit}
                        href={directSourceTrackLink(row.sourceTrackId, source)}>{row.name}</a>
                    </TableCell>
                    <TableCell className={cellClasses.inherit} align="center">{row.status}</TableCell>
                    <TableCell className={cellClasses.inherit} align="center">
                      {(row.destinationTrackId && row.destinationTrackId !== '' && row.status !== 'failed')
                        && <a target="_blank" className={cellClasses.inherit}
                          href={directDestinationTrackLink(row.destinationTrackId, destination)}>
                          {row.name}
                        </a>
                      }
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};
export default EnhancedTable;
