import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { selectApplication as useStyles } from './provider.styles';
import providerInterface from './provider.interface';

interface IProps {
  selected: providerInterface;
  // eslint-disable-next-line no-unused-vars
  setSelected: (provider: providerInterface) => void;
  listOfApps: Array<providerInterface>;
}
const NativeSelects: React.FC<IProps> = ({ selected, setSelected, listOfApps }: IProps) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const id = event.target.value as keyof typeof event;
    const foundItem = listOfApps.find((app) => app.id === id) || listOfApps[0];
    setSelected(foundItem);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        native
        value={selected.id}
        onChange={handleChange}
      >
        {listOfApps.map((app) => (
          <option key={app.id} value={app.id}>{app.title}</option>
        ))}
      </Select>
    </FormControl>
  );
};
export default NativeSelects;
