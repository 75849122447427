import {
  createStyles, lighten, makeStyles, Theme,
} from '@material-ui/core/styles';

export const useToolbarStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    tableLayout: 'fixed',
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  title: {
    flex: '1 1 100%',
  },
}));

export const useTableStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  tableBodyScroll: {
    maxHeight: 400,
    overflowY: 'auto',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {

  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  albumart: {
    display: 'inline-flex',
  },
  tableName: {
    maxWidth: 75,
  },
}));

export const useTableCellStyles = makeStyles(() => createStyles({
  inherit: {
    color: 'inherit !important',
    textDecoration: 'none',
    minWidth: '100px',
  },
}));
