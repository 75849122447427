import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  container100: {
    position: 'relative',
    minHeight: '100vh',
  },
  fixedFooter: {
    [theme.breakpoints.up('sm')]: {
      padding: '5px',
      bottom: '0',
      display: 'inline-block',
      left: '0',
      right: '0',
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'absolute',
    },
  },
}));
