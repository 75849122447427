import type { TrackLog, Transfer } from '@playlist/types';
import type { providerInterface } from '../../provider';

export function convertTrackLogs(tracks: { [x: string]: TrackLog }) {
  if (tracks) {
    return Object.values(tracks).map((track) => track);
  }
  return [];
}

export function convertTransferLog(transfer: Transfer) {
  if (transfer.tracks) {
    const tracks = convertTrackLogs(transfer.tracks);
    return { ...transfer, tracks };
  }
  return { ...transfer, tracks: [] };
}

export function getTransferdTracks(tracks: TrackLog[]) {
  return tracks?.filter((track) => track.isTransfered)?.length || 0;
}

export function getNotTransferdTracks(tracks: TrackLog[]) {
  return tracks?.filter((track) => track.error
    || (track.isSearched && !track.isFound))?.length || 0;
}

export function getFoundTracks(tracks: TrackLog[]) {
  return tracks?.filter((track) => track.isFound)?.length || 0;
}

export function getNotFoundTracks(tracks: TrackLog[]) {
  return tracks?.filter(({ isSearched, isFound, error }) => isSearched
    && (!isFound || error))?.length || 0;
}

export function getPercentage(numerator: number, denominator: number) {
  let denominatorSafe = 1;
  if (denominator > 1) {
    denominatorSafe = denominator;
  }
  const ratio = numerator / denominatorSafe;
  if (ratio <= 1) {
    return Math.round(ratio * 100);
  }
  return 100;
}

export const makeSourceTrackLink = (
  track: TrackLog,
  provider?: providerInterface,
) => `${provider?.trackBaseLink}/${track.sourceTrackId}`;

export const makeDestinationTrackLink = (
  track: TrackLog,
  provider?: providerInterface,
) => `${provider?.trackBaseLink}/${track.destinationTrackId}`;

export const TransferTrackStatusText = (track: TrackLog) => {
  if (track.error) return 'failed';
  if (track.isTransfered) return 'success';
  if (track.isFound) return 'pending';
  if (!track.isSearched) return 'pending';
  return 'failed';
};
