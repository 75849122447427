import React from 'react';
import Grid from '@material-ui/core/Grid';
import type { Transfer } from 'types/typing';
import { Link } from 'react-router-dom';
import { ArrowForward, CheckTwoTone } from '@material-ui/icons';
import {
  Avatar, Tooltip, Paper, LinearProgress, Link as MLink,
} from '@material-ui/core';
import { useTransferItemStyle, useIconStyle } from './item.styles';
import { ProviderAvatar } from '../../provider';
import { useTransferItem } from './item.hooks';
import { getlinkToTransfer } from '../transfer.helpers';

const TransferItemComplete: React.FC<{
  transferId: string;
  transfer: Transfer;
}> = function TransferItemComponent({ transferId, transfer }) {
  const classes = useTransferItemStyle();
  const iconClasses = useIconStyle();
  const link = getlinkToTransfer(transferId);
  const {
    failRate, successRate, source, destination,
  } = useTransferItem({ transfer });
  return (
    <Grid
      container
      className={classes.item}
      component={Paper}
      variant="outlined"
      justify="space-between"
      alignItems="center">
      <div className={classes.tab}>
        <Grid className={classes.tabTitle} container spacing={1}>
          <Grid className={classes.noPadding} item xs={4} sm={4}>
            {source
              && <Tooltip title={source.id} aria-label="source">
                <ProviderAvatar className={iconClasses.icon} provider={source} />
              </Tooltip>
            }
          </Grid>
          <Grid className={classes.arrowMiddle} item xs={4} sm={4}>
            <ArrowForward />
          </Grid>
          <Grid className={classes.noPadding} item xs={4} sm={4}>
            {destination
              && <Tooltip title={destination.id} aria-label="destination">
                <ProviderAvatar className={iconClasses.icon} provider={destination} />
              </Tooltip>
            }
          </Grid>
        </Grid>
      </div>
      <Grid className={classes.name} item xs>
        <Tooltip title='click to see details about the transfer' aria-label="name">
          <MLink underline='none' component={Link} to={link}> {transfer.name} </MLink>
        </Tooltip>
      </Grid>
      {transfer.isCompleted
        && <CheckTwoTone color='primary' />}

      <Grid item>
        <Tooltip title='successfully transfered tracks' aria-label="successfully transfered tracks">
          <Avatar className={iconClasses.successIcon}>{successRate}</Avatar>
        </Tooltip>
        <Tooltip title='not found tracks' aria-label="not found tracks">
          <Avatar className={iconClasses.failIcon}>{failRate}</Avatar>
        </Tooltip>

      </Grid>
      {!transfer.isCompleted
        && <div className={classes.progressBar}>
          <LinearProgress />
        </div>
      }
    </Grid>
  );
};

export default TransferItemComplete;
