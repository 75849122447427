import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TrackLog, Transfer } from 'types/typing';
import { DB } from '../utils/firebase';
import { setTransfers } from '../slices/user.slice';
import useUser from './user.hooks';

const useTransfer = () => {
  const dispatch = useDispatch();
  const user = useUser();
  useEffect(() => {
    if (user) {
      const transferRef = DB.collection('transfers');
      const unsubscribe = transferRef
        .doc(user.uid)
        .collection('transfers')
        .onSnapshot(async (snapshot) => {
          if (!snapshot.empty) {
            const transferPromises = snapshot.docs
              .map(({ ref }) => ref.get()
                .then((snapTransfer) => ({ id: snapTransfer.id, value: snapTransfer.data() })))
              .filter((i) => typeof i !== 'undefined');
            const transfers = (await Promise.all(transferPromises))
              .reduce((totalTransferFields, currentTransfer) => {
                const { id, value } = currentTransfer as { id: string; value: Transfer };
                const newTransferFiled : { [x: string]: Transfer } = {};
                newTransferFiled[id] = value;
                return { ...totalTransferFields, ...newTransferFiled };
              }, {});
            dispatch(setTransfers(transfers));
          }
        });
      return () => {
        unsubscribe();
      };
    }
    return () => {};
  }, [dispatch, user]);
};

export default useTransfer;

export const useTrack = (transferId: string) => {
  const dispatch = useDispatch();
  const [tracks, setTracks] = useState<TrackLog[]>([]);
  const user = useUser();
  useEffect(() => {
    if (user) {
      const transferTrackRef = DB
        .collection('transfers').doc(user.uid)
        .collection('transfers').doc(transferId)
        .collection('tracks');
      const unsubscribe = transferTrackRef
        .onSnapshot(async (snapshot) => {
          if (!snapshot.empty) {
            const transferTracks = snapshot.docs
              .map((doc) => doc.data() as TrackLog);
            setTracks(transferTracks);
          }
        });
      return () => {
        unsubscribe();
      };
    }
    return () => {};
  }, [dispatch, user]);
  return tracks;
};
