import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../slices/user.slice';
import appReducer from '../slices/app.slice';
import playlistReducer from '../slices/playlist.slice';

export default configureStore({
  reducer: {
    user: userReducer,
    app: appReducer,
    playlist: playlistReducer,
  },
});
