import { post, del } from './http-client';

export function registerUser(token: string) {
  const url = '/auth/check/register';
  return post(url, { token });
}

export function unRegisterUser() {
  const url = '/auth/check/remove';
  return del(url);
}
