import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  authCheckerWrapper: {
    width: '100%',
    textAlign: 'center',
    marginTop: 50,
    marginBottom: 50,
  },
  authCheckerContent: {
    marginBottom: 20,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
