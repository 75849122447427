import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Transfer from './transfer.component';
import TransferDetails from './transfer-details.component';
import FourOFour from '../404';

const Root = () => (
  <Switch>
    <Route path="/transfer" component={Transfer}>
        <Route exact path="/transfer/report/:id">
          <TransferDetails />
        </Route>
        <Route exact path="/transfer/report">
          <Transfer />
        </Route>
      </Route>
      <Route component={FourOFour} />
  </Switch>
);

export default Root;
