import {
  createStyles, makeStyles,
} from '@material-ui/core/styles';

// eslint-disable-next-line import/prefer-default-export
export const titlebarClass = makeStyles(() => createStyles({
  parent: {
    position: 'relative',
  },
  theIcon: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  titleBotton: {
    display: 'inline-flex',
    margin: '0 16px',
    verticalAlign: 'super',
  },
}));
