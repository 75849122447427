import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsStartTransferAfterGo, selectProviders, setIsStartTransferAfterGo } from '../../../slices/app.slice';
import { selectUser } from '../../../slices/user.slice';
import { selectSelectedPlaylistIds, setSelectedPlaylistIds } from '../../../slices/playlist.slice';
import { post } from '../../../utils/http-client';
import { openLoginPopupForProvider, PROVIDERS_LIST } from '../../provider';
import type { providerInterface } from '../../provider';
import { useSnacker } from '../../snacker';

const useTransferToolbox = () => {
  const user = useSelector(selectUser);
  const { source, destination } = useSelector(selectProviders);
  const [localSource, setlocalSource] = useState<providerInterface>(PROVIDERS_LIST[0]);
  const [localDestination, setLocalDestination] = useState<providerInterface>(PROVIDERS_LIST[1]);
  const [
    lastLocalDestination,
    setLastLocalDestination,
  ] = useState<providerInterface>(PROVIDERS_LIST[1]);
  const [disable, setDisable] = useState<boolean>(true);
  const selectedPlaylistIds = useSelector(selectSelectedPlaylistIds);
  const isStartTransferAfterGo = useSelector(selectIsStartTransferAfterGo);
  const dispatch = useDispatch();
  const history = useHistory();
  const { Snacker, snacks, showSnack } = useSnacker();
  const listOfAvailableProviders = useMemo(() => PROVIDERS_LIST
    .filter((i) => i.id !== localSource.id), [localSource]);

  const startTransfer = useCallback(async (__playlists: string[] = []) => {
    if (localSource?.id && localDestination?.id) {
      showSnack({ text: 'Please wait! Transfer is going to start...', type: 'success' });
      dispatch(setSelectedPlaylistIds([]));
      const { data }: { data: { creationDate: string } } = await post('playlist/transfer/begin', {
        playlists: __playlists,
        source: localSource.id,
        destination: localDestination.id,
      });
      if (data.creationDate) {
        history.push('/transfer/report');
      }
    } else {
      showSnack({ text: `{${localSource?.id}, ${localDestination?.id}} aren't valid!`, type: 'error' });
    }
  }, [localDestination.id, localSource.id]);

  const goAction = () => {
    setLastLocalDestination(localDestination);
    if (selectedPlaylistIds?.length > 0 && source.id && localDestination.id) {
      const hasProperty = Object.prototype.hasOwnProperty
        .call(user.providers, localDestination.id);
      if (hasProperty) {
        startTransfer(selectedPlaylistIds);
      } else {
        dispatch(setIsStartTransferAfterGo(true));
        openLoginPopupForProvider(localDestination.id);
      }
    } else {
      showSnack({ text: 'can\'t start transfer', type: 'warning' });
    }
  };

  // localize providers
  useEffect(() => {
    setlocalSource(source);
    setLocalDestination(destination);
  }, [source, destination]);

  // start transfer after distination login
  useEffect(() => {
    if (localDestination.id === lastLocalDestination.id) {
      if (isStartTransferAfterGo) {
        const hasProperty = Object.prototype.hasOwnProperty
          .call(user.providers, localDestination.id);
        if (hasProperty) {
          startTransfer(selectedPlaylistIds);
          dispatch(setIsStartTransferAfterGo(false));
        }
      }
    } else {
      setIsStartTransferAfterGo(false);
      setLastLocalDestination(localDestination);
    }
  }, [
    user.providers, isStartTransferAfterGo,
    dispatch, startTransfer,
    selectedPlaylistIds, localDestination.id,
  ]);

  // disable (go button)
  useEffect(() => {
    const isAnyPlaylistSelected = selectedPlaylistIds.length > 0;
    setDisable(!isAnyPlaylistSelected);
  }, [selectedPlaylistIds]);

  return {
    goAction,
    setLocalDestination,
    localDestination,
    listOfAvailableProviders,
    disable,
    Snacker,
    snacks,
  };
};

export default useTransferToolbox;
